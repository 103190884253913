.about {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
}

.about button {
	margin: 25px;
	display: inline-block;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	padding: 0;
}

@media screen and (max-width: 720px) {
	.about {
		flex-direction: column;
	}

	.about button {
		width: 150px;
		height: 50px;
		font-size: 13px;
	}
}

@media screen and (min-width: 720px) {
	.about button {
		width: 110px;
		height: 50px;
		font-size: 12px;
	}
}

@media screen and (min-width: 768px) {
	.about button {
		width: 130px;
		height: 55px;
		font-size: 13px;
	}
}

@media screen and (min-width: 1000px) {
	.about button {
		width: 200px;
		height: 75px;
		font-size: 20px;
	}
}

@media screen and (min-width: 1600px) {
	.about button {
		width: 230px;
		height: 80px;
		font-size: 22px;
	}
}
