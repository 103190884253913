.text-animation {
	font-family: monospace;
	display: flex;
	justify-content: center;
	overflow: hidden;
	text-align: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
